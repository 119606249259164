import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static classes = ['fixed']

  connect() {
    this.reposition()
  }

  reposition() {
    this.element.classList.remove(this.fixedClass)

    let offset = this.element.offsetTop + this.element.offsetHeight
    let bottom = window.innerHeight + window.scrollY

    if (offset > bottom) {
      this.element.classList.add(this.fixedClass)
    } else {
      this.element.classList.remove(this.fixedClass)
    }
  }
}
