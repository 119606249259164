import ApplicationController from 'controllers/application_controller'
import _ from 'underscore'

export default class extends ApplicationController {
  static targets = ['selectable']

  static classes = ['itemSelected']

  selectAll (event) {
    this.selectableTargets.forEach(
      (selectable) => {
        if (!selectable.classList.contains(this.itemSelectedClass)) {
          this.clickSelectable(selectable)
        }
      }
    )

    event.preventDefault()
  }

  selectNone (event) {
    this.selectableTargets.forEach(
      (selectable) => {
        if (selectable.classList.contains(this.itemSelectedClass)) {
          this.clickSelectable(selectable)
        }
      }
    )

    event.preventDefault()
  }

  clickSelectable (selectable) {
    let event = new Event('click')
    selectable.dispatchEvent(event)
  }
}