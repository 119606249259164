import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [
    'counter'
  ]

  static classes = [
    'goodPerformance',
    'mediumPerformance',
    'badPerformance'
  ]

  static values = {
    refreshInterval:   { type: Number, default:  5 },
    goodPerformance:   { type: Number, default: 50 },
    mediumPerformance: { type: Number, default: 25 }
  }

  get fps () {
    return this._fps || 0
  }

  set fps (newValue) {
    this._fps = newValue
    this.counterUpdates++

    if ((this.counterUpdates % this.refreshIntervalValue) == 0) {
      this.updateCounter()
    }
  }

  connect () {
    this.enabled        = true
    this.counterUpdates = 0
    this.measurements   = []
    this.updateFPS      = this.updateFPS.bind(this)
    this.refreshFPS()
  }

  disconnect () {
    this.enabled = false
  }

  updateCounter () {
    let element = this.hasCounterTarget ? this.counterTarget : this.element

    element.innerHTML = this.fps

    if (this.fps >= this.goodPerformanceValue) {
      this.element.classList.add(this.goodPerformanceClass)
      this.element.classList.remove(this.mediumPerformanceClass)
      this.element.classList.remove(this.badPerformanceClass)
    } else if (this.fps >= this.mediumPerformanceValue) {
      this.element.classList.remove(this.goodPerformanceClass)
      this.element.classList.add(this.mediumPerformanceClass)
      this.element.classList.remove(this.badPerformanceClass)
    } else {
      this.element.classList.remove(this.goodPerformanceClass)
      this.element.classList.remove(this.mediumPerformanceClass)
      this.element.classList.add(this.badPerformanceClass)
    }
  }

  refreshFPS () {
    if (this.enabled) {
      window.requestAnimationFrame(this.updateFPS)
    }
  }

  updateFPS () {
    let now = performance.now()

    while (this.measurements.length > 0 && this.measurements[0] <= (now - 1000)) {
      this.measurements.shift()
    }

    this.measurements.push(now)
    this.fps = this.measurements.length
    this.refreshFPS()
  }
}
