import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {
  static targets = ['width', 'height']

  connect() {
    this.populateDimensions();
  }

  populateDimensions() {
    this.widthTarget.value = screen.width;
    this.heightTarget.value = screen.height;
  }
}