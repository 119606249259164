import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = [
    'allFilters'
  ]

  static classes = [
    'isVisible',
    'isHidden'
  ]

  connect () {
    this.allFiltersTarget.classList.remove(this.isVisibleClass)
  }

  toggleFilters(event) {
    event.preventDefault()
    this.allFiltersTarget.classList.toggle(this.isVisibleClass)
  }
}