import _ from 'underscore'
import ApplicationController from '../../application_controller'

export default class extends ApplicationController {

  static targets = [
    'dateInput',
    'startsAtInput',
    'endsAtInput',
    'guestsInput',
    'tableLock',
    'tableSelect'
  ]

  static values = {
    reservationId: Number,
    availableUrl:  String
  }

  get tableSelectOptions() {
    return Array.from(this.tableSelectTarget.options)
  }

  connect() {
    this.updateAvailableTableOptions = this.updateAvailableTableOptions.bind(this)
    this.updateTablesSelect()
  }

  tableLock(event) {
    if(this.tableSelectTarget.selectedOptions.length > 0 ){
      this.tableLockTarget.checked = 'checked'
    }else {
      this.tableLockTarget.checked = ''
    }
  }

  updateAvailableTableOptions(availableIds) {
    let enabledIds = this.tableSelectOptions.filter(
      (option) => option.dataset.occupied == 'false'
    ).map(
      (option) => parseInt(option.value)
    ).sort()

    let anythingChanged = !!(
      (availableIds.length != enabledIds.length) ||
      availableIds.find((value, index) => enabledIds[index] != value)
    )

    if (!anythingChanged) {
      return
    }

    this.tableSelectOptions.forEach((option) => {
      let optionId    = parseInt(option.value)
      let isAvailable = availableIds.indexOf(optionId) >= 0

      option.dataset.occupied = !isAvailable
    })

    _.defer(
      () => this.tableSelectTarget.dispatchEvent(new Event('change'))
    )
  }

  updateTablesSelect(event) {
    let params = {
      date:      this.dateInputTarget.value,
      starts_at: this.startsAtInputTarget.value,
      ends_at:   this.endsAtInputTarget.value
    }

    if(this.reservationIdValue) {
      params['reservation_id'] = this.reservationIdValue
    }

    params  = _.map(params, (value, key) => `${key}=${value}`).join('&')
    let url = `${this.availableUrlValue}?${params}`

    // fetch available table ids and update available tables:
    fetch(url).then(
      (response) => response.json()
    ).then(
      (json) => {
        let availableTableIds = json.available_table_ids.map((id) => parseInt(id)).sort()
        this.updateAvailableTableOptions(availableTableIds)
      }
    )
  }
}
