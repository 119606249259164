import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static outlets = [
    'backend--table',
    'backend--combination'
  ]


  // SETUP:

  connect () {
  }

  toggleTable (event) {
    if (event.params.id) {
      let tableId = parseInt(event.params.id)
      let table   = this.backendTableOutlets.find(
        (table) => table.idValue == tableId
      )

      let combinations = this.backendCombinationOutlets.filter(
        (combination) => combination.selectedTableIds.indexOf(table.idValue) >= 0
      )

      if (table && combinations.length > 0) {
        combinations.forEach((combination) => {
          this.updateCombinationActiveStatus(combination)
        })
      }
    }
  }

  updateCombinationActiveStatus (combination) {
    let tables = this.backendTableOutlets.filter(
      (table) => combination.selectedTableIds.indexOf(table.idValue) >= 0
    )

    let disabled = tables.find((table) => !table.enabled)

    if (disabled) {
      combination.disable()
    } else {
      combination.enable()
    }
  }
}