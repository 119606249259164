import ApplicationController from 'controllers/application_controller'
import Cookies from 'vendor/js.cookie'


export default class extends ApplicationController {

  static targets = [
    'cellIcons'
  ]

  static classes = [
    'hiddenRow',
    'tableRowsHidden',
  ]

  static outlets = [
    'timeline--row'
  ]

  get cookie() {
    return this.getCookie()
  }

  set cookie(newSetting) {
    this.setCookie(newSetting)
  }

  connect() {
    if (this.cookie == 'hide') {
      this.hide()
    } else {
      this.show()
    }
  }

  toggle(event) {
    if (this.cookie == 'show') {
      this.hide()
    } else {
      this.show()
    }
  }

  reflow() {
    // Force reflow in Safari
    let oldStyleDisplay = this.element.style.display
    this.element.style.display = 'none'
    this.element.style.display = oldStyleDisplay
  }

  show (event) {
    this.cookie = 'show'

    if (this.hasCellIconsTarget) {
      this.cellIconsTarget.classList.remove(this.tableRowsHiddenClass)
    }

    this.timelineRowOutlets.forEach(
      (row) => row.element.classList.remove(this.hiddenRowClass)
    )

    this.reflow()
  }

  hide (emptyTable) {
    this.cookie = 'hide'

    if (this.hasCellIconsTarget) {
      this.cellIconsTarget.classList.add(this.tableRowsHiddenClass)
    }

    this.timelineRowOutlets.forEach(
      (row) => {
        if (row.timelineReservationOutlets.length == 0) {
          row.element.classList.add(this.hiddenRowClass)
        }
      }
    )

    this.reflow()
  }

  // PRIVATE

  setCookie(setting) {
    Cookies.set('hide-empty-tables', setting)
  }

  getCookie() {
    var setting = Cookies.get('hide-empty-tables')

    if (setting != 'hide' && setting != 'show') {
      this.setCookie(setting = 'hide')
    }

    return setting
  }
}

