import ApplicationController from '../../application_controller'

export default class extends ApplicationController {

  static targets = [
    'sendEmail',
    'sendNotification',
    'sendUpdateEmailInput',
    'sendNotificationsInput'
  ]

  sendUpdateEmail(event) {
    if(this.hasSendUpdateEmailInputTarget) {
      let input = event.currentTarget

      if(!input.hasAttribute('sendUpdateEmailOff')) {
        this.sendUpdateEmailInputTarget.setAttribute('checked', true)
      }
    }
  }

  // turnoff automatic turning on email
  automaticSendUpdateEmailOff() {
    if(this.sendEmailTargets.length > 1){
      this.sendEmailTargets.foreach(target => {
        target.setAttribute('sendUpdateEmailOff', true)
      })
    }else if(this.hasSendEmailTarget){
      this.sendEmailTarget.setAttribute('sendUpdateEmailOff', true)
    }
  }

  sendNotification(event) {
    let input = event.currentTarget
    let EMAIL_REGEXP = /^\S+@\S+$/

    if(input.value.match(EMAIL_REGEXP) && !input.hasAttribute('sendNotificationOff')) {
      this.sendNotificationsInputTarget.setAttribute('checked', true)
    }
  }

  automaticSendNotificationOff(event) {
    if(this.sendNotificationTargets.length > 1) {
      this.sendNotificationTargets.foreach(target => {
        target.setAttribute('sendNotificationOff', true)
      })
    }else if(this.hasSendNotificationTarget){
      this.sendNotificationTarget.setAttribute('sendNotificationOff', true)
    }
  }
}
