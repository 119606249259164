import ApplicationController from '../../application_controller'

export default class extends ApplicationController {

  static targets = [
    'timeInput',
    'dateInput',
    'endsAtInput'
  ]

  durations() {
    let durations = JSON.parse(this.timeInputTarget.dataset.durations)
    let lunch = []
    let dinner = []

    lunch = durations.lunch.split(':').map(value => parseInt(value))
    dinner = durations.dinner.split(':').map(value => parseInt(value))

    let settings = { 'lunch': lunch,
                     'dinner': dinner,
                     'days': durations.days }
    return settings
  }


  timeIncrease() {
    let settings = this.durations()

    let timeValue = this.timeInputTarget.value
    let dateValue = this.dateInputTarget.value
    let date = {}

    if(dateValue.length > 0) {
      try {
        let parts = dateValue.split('-')
        date = new Date(parts[0], parseInt(parts[1]) - 1, parts[2])
      }catch {
        date = false
      }
    }
     // return settings.dinner unless timeValue?.length && date?
    if(timeValue !== null && date == null) {
      return settings.dinner
    }

    // weekdays start on sunday in Javascript
    let weekDay = date.getDay() - 1
    if(weekDay == -1) {
      weekDay = 6
    }

    let hours = settings.days[weekDay]

    let time = Date.parse(new Date(2000, 1, 1, ...Array.from(timeValue.split(':'))))
    let lunch = { 'from': new Date(2000, 1, 1, ...Array.from(hours.lunch_from.split(':'))),
                  'till': new Date(2000, 1, 1, ...Array.from(hours.lunch_till.split(':')))
                }

    let dinner = { 'from': new Date(2000, 1, 1, ...Array.from(hours.dinner_from.split(':'))),
                   'till': new Date(2000, 1, 1, ...Array.from(hours.dinner_till.split(':')))
                 }

    if(hours.lunch_from && hours.lunch_till && time >= lunch.from && time < lunch.till) {
      return settings.lunch
    }
    else if(hours.dinner_from && hours.dinner_till && time >= dinner.from && time < dinner.till) {
      return settings.dinner
    }
    else if(hours.dinner_from  && time < dinner.from) {
      return settings.lunch
    }

    return settings.dinner
  }

  // on reservation time change
  fromTimeChange() {
    let fromValue = this.timeInputTarget.value

    if(fromValue == null){
      return
    }

    let difference = this.timeIncrease();

    let newTill = fromValue.split(':').map((value, index) => {
      return parseInt(value) + parseInt(difference[index])
    })

    // Handle hour overflow:
    if(newTill[1] >= 60) {
      newTill[0] += 1
      newTill[1] -= 60
    } else if(newTill[1] < 0) {
      newTill[0] -= 1
      newTill[1] += 60
    }

    // Handle day overflow
    if(newTill[0] >= 24) {
      newTill[0] -= 24
    }else if(newTill[0] < 0) {
      newTill[0] += 24
    }

    // Convert "1:5" to "01:05":
    newTill = newTill.map(result => {
      if(result < 10) {
        return `0${result}`
      }else {
        return result
      }
    })

    this.endsAtInputTarget.value = newTill.join(':')
  }
}
