import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static outlets = [
    'backend--table'
  ]

  static values = {
    id: Number
  }

  static targets = [
    'minimumGuestsInput',
    'maximumGuestsInput',
    'enabledCheckbox',
    'tablesSelect'
  ]

  get selectedOptions() {
    return Array.from(this.tablesSelectTarget.selectedOptions)
  }

  get selectedTableIds() {
    return this.selectedOptions.map(
      (option) => parseInt(option.value)
    )
  }

  get selectedTableOutlets() {
    return this.backendTableOutlets.filter(
      (table) => this.selectedTableIds.indexOf(table.idValue) >= 0
    )
  }

  get enabled () {
    return this.enabledCheckboxTarget.checked
  }

  get manuallyChanged () {
    return this.element.classList.contains('changed-manually')
  }

  // SETUP:

  connect () {

  }

  // ACTIONS:

  enable () {
    if (!this.enabled) {
      this.enabledCheckboxTarget.checked = true

      let changeEvent = new Event('change')
      this.enabledCheckboxTarget.dispatchEvent(changeEvent)
    }
  }

  disable () {
    if (this.enabled) {
      this.enabledCheckboxTarget.checked = false

      let changeEvent = new Event('change')
      this.enabledCheckboxTarget.dispatchEvent(changeEvent)
    }
  }

  changedManually (event) {
    this.element.classList.add('changed-manually')
  }

  updateTableStatus (event) {
    if (this.enabled) {
      this.selectedTableOutlets.forEach(
        (table) => table.enable()
      )
    }
  }

  updateCapacity(event) {
    if (this.manuallyChanged) { return }

    let minimumGuests = 0
    let maximumGuests = 0

    this.selectedOptions.forEach(
      (option) => {
        minimumGuests += parseInt(option.dataset.minimumGuests)
        maximumGuests += parseInt(option.dataset.maximumGuests)
      }
    )

    if (this.hasMinimumGuestsInputTarget) {
      this.minimumGuestsInputTarget.value = minimumGuests
    }

    if (this.hasMaximumGuestsInputTarget) {
      this.maximumGuestsInputTarget.value = maximumGuests
    }
  }
}
