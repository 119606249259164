import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ['input', 'submit']

  static values = {
    autoSubmit: { type: Boolean, default: false }
  }

  connect () {
    this.submitTargets.forEach((submitTarget) => {
      submitTarget.disabled = true
    })
  }

  handleInput (event) {
    this.submitTargets.forEach((submitTarget) => {
      submitTarget.disabled = false
    })

    if (this.autoSubmitValue) {
      let form = event.target.form
      if (form) { form.requestSubmit() }
    }
  }
}
