import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['details']

  static classes = ['communicationLogOpen']

  connect() {
  }

  toggle(event) {
    event.preventDefault()
    let target = event.currentTarget

    // toggle text
    let toggleText = event.params.toggle
    target.dataset.communicationLogToggleParam = target.innerHTML
    this.morph(target, toggleText)

    // Show/hide details:
    this.element.classList.toggle(this.communicationLogOpenClass)
  }
}
