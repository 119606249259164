import ApplicationController from '../application_controller'
import toggleDisplay from 'lib/toggle_display'

export default class extends ApplicationController {

  static targets = [
    'notifyByPhoneInput',
    'callDaysFieldset'
  ]

  connect() {
    this.hideOrShowCallTimesFormForCheckbox()
  }

  hideOrShowCallTimesFormForCheckbox() {
    if (this.hasNotifyByPhoneInputTarget) {
      if (this.notifyByPhoneInputTarget.checked) {
        toggleDisplay(this.callDaysFieldsetTarget, true)
      } else {
        toggleDisplay(this.callDaysFieldsetTarget, false)
      }
    }
  }
}
