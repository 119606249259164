import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = [ 'horizontalScroll' ]

  static classes = [ 
    'isScrolling', 
    'isScrollingX', 
    'isScrollingY' 
  ]

  connect () {
    this.scrollBody  = this.element
    this.scrollTimer = null
  }

  detectScroll () {
    let scrollFlag   = false
    let scrollBody   = this.scrollBody

    let isScrolling  = this.isScrollingClass
    let isScrollingX = this.isScrollingXClass
    let isScrollingY = this.isScrollingYClass

    if (!scrollFlag) {
      scrollFlag = true
      scrollBody.classList.add(isScrolling)

      // Detect horzintal scrolling (div)
      if (this.oldScrollX != this.horizontalScrollTarget.scrollLeft) {
        this.oldScrollX = this.horizontalScrollTarget.scrollLeft
        scrollBody.classList.add(isScrollingX)
      }

      // Detect vertical scrolling (window)
      if (this.oldScrollY != window.scrollY) {
        this.oldScrollY = window.scrollY
        scrollBody.classList.add(isScrollingY)
      }
    }

    clearTimeout(this.scrollTimer)

    this.scrollTimer = setTimeout(function() {
      scrollBody.classList.remove(isScrolling, isScrollingX, isScrollingY)
    }, 300)
  }
}