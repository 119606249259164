import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static values = {
    groupingTemplate:  String,
    conditionTemplate: String
  }

  get currentTimestamp() {
    return new Date().getTime()
  }

  connect() {
    this.refresh()
  }

  refresh() {
    this.element.querySelectorAll('.advanced-search-combinator select').forEach(this.updateCombinatorForSelect.bind(this))
  }

  updateCombinatorForSelect(select) {
    let group  = select.closest('.advanced-search-grouping')
    let option = select.options[select.selectedIndex]
    let value  = option && option.value

    switch(value) {
      case 'and':
        group.classList.remove('advanced-search-or-grouping')
        group.classList.add('advanced-search-and-grouping')
        break

      case 'or':
        group.classList.remove('advanced-search-and-grouping')
        group.classList.add('advanced-search-or-grouping')
        break

      default:
        group.classList.remove('advanced-search-and-grouping')
        group.classList.remove('advanced-search-or-grouping')
    }
  }

  removeFields(event) {
    event.preventDefault()

    let button = event.target
    let fields = button.closest('.advanced-search-fields')

    fields && fields.remove()
  }

  addFields(event) {
    event.preventDefault()

    let button = event.target
    let type   = event.params.fieldType

    let template = this[`${type}TemplateValue`]
    let regexp   = new RegExp(`new_${type}`, 'g')

    let result = template.replace(regexp, this.currentTimestamp)

    button.insertAdjacentHTML('beforebegin', result)
    this.refresh()
  }

  nestFields(event) {
    event.preventDefault()

    let button = event.target
    let type   = event.params.fieldType

    let template = this[`${type}TemplateValue`]
    let regexp   = new RegExp(`new_${type}`, 'g')

    let fieldsParent = button.closest('.advanced-search-fields')
    let unsanitizedObjectName = fieldsParent.dataset.objectName
    let sanitizedObjectName   = unsanitizedObjectName.replace(/\]\[|[^-a-zA-Z0-9:.]/g, '_').replace(/_$/, '')

    let result = template.replace(
      /new_object_name\[/g,
      `${unsanitizedObjectName}[`
    ).replace(
      /new_object_name_/,
      `${sanitizedObjectName}_`
    ).replace(
      regexp,
      this.currentTimestamp
    )

    button.insertAdjacentHTML('beforebegin', result)
    this.refresh()
  }

  changeCombinator(event) {
    this.updateCombinatorForSelect(event.target)
  }
}
