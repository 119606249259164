import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['totalInput', 'message', 'count']

  static values = {
    total:  { type: Number, default: null },
    ticker: { type: Number, default: 0    }
  }

  static classes = ['hidden']

  connect () {
    this.updateValues()
  }

  updateValues () {
    if (this.hasTotalInputTarget) {
      let value = this.totalInputTarget.value
      if (value && value.length) {
        this.totalValue = Math.max(0, parseInt(value) - this.tickerValue)
      } else {
        this.totalValue = null
      }
    }

    if (this.hasCountTarget) {
      this.countTarget.innerText = this.totalValue
    }

    if (this.hasMessageTarget) {
      let isHidden = isNaN(this.totalValue)
      this.messageTarget.classList.toggle(this.hiddenClass, isHidden)
    }
  }
}
