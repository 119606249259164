import ApplicationController from './application_controller'
import _ from 'underscore'

export default class extends ApplicationController {

  // SETTINGS:

  static targets = [
    'select',           'optgroup',    'option',                         // Form elements
    'selectWrapper',                                                     // Wrapper for CSS purposes
    'itemsContainer',   'itemsHeader', 'itemsList', 'itemGroup', 'item', // Available options
    'choicesContainer', 'choice'                                         // Chosen options
  ]

  static classes = [
    'hidden',
    'visible',
    'selected',
    'disabled',
    'selectedOptions'
  ]

  static values = {
    closeOnSelect: {
      type:    Boolean,
      default: false
    },
    openListLabel: {
      type:    String,
      default: 'Add'
    },
    selectWrapperTemplate: {
      type:    String,
      default: (
        '<div class="multi-select-container"  data-multi-select-target="selectWrapper">' +
          '<span class="multi-select-toggle" data-action="click->multi-select#toggleItemList">' +
            '${this.openListLabelValue}' +
          '</span>' +
          '${select}' +
        '</div>'
      )
    },
    itemsContainerTemplate: {
      type:    String,
      default: (
        '<div class="multi-select-items-container" data-multi-select-target="itemsContainer">' +
        '</div>'
      )
    },
    itemListTemplate:  {
      type:    String,
      default: (
        '<ol class="multi-select-items-list" data-multi-select-target="itemsList">' +
          '${items}' +
        '</ol>'
      )
    },
    itemGroupTemplate: {
      type:    String,
      default: (
        '<li class="multi-select-item-group">${optgroup.label}</li>' +
        '${items}'
      )
    },
    itemTemplate: {
      type:    String,
      default: (
        '<li class="multi-select-item" data-multi-select-target="item" data-action="click->multi-select#toggleOption" data-multi-select-value-param="${option.value}">' +
          '${option.label}' +
        '</li>'
      )
    },
    choicesContainerTemplate: {
      type:    String,
      default: (
        '<ul class="multi-select-choices-list" data-multi-select-target="choicesContainer">' +
        '</ul>'
      )
    },
    choiceTemplate: {
      type:    String,
      default: (
        '<li class="multi-select-choice" data-multi-select-target="choice">' +
          '<span>' +
            '${option.label}' +
            '<a class="multi-select-choice-close" data-action="click->multi-select#deselectOption" data-multi-select-value-param="${option.value}"></a>' +
          '</span>' +
        '</li>'
      )
    }
  }

  // SETUP:

  connect () {
    this.updateItemListHeight = this.updateItemListHeight.bind(this)
    this.resetItemListHeight  = this.resetItemListHeight.bind(this)

    if (!this.hasSelectTarget) {
      console.error("Can't use multi-select controller without a valid <select data-multi-select-target=\"select\"></select>")
      return
    }

    this.wrapSelect()
    this.selectTarget.classList.add(this.hiddenClass)

    this.createDefaultItemsContainer()
    this.closeItemList()
    this.addAvailableOptionsToItemsContainer()
    this.updateItemStates()

    this.createDefaultChoicesContainer()
    this.updateChoiceStates()
  }

  wrapSelect () {
    if (this.hasSelectWrapperTarget) {
      return
    }

    let selectElement = this.selectTarget

    let selectHTML  = selectElement.outerHTML
    let wrapperHTML = this.render(this.selectWrapperTemplateValue, { select: selectHTML })

    selectElement.insertAdjacentHTML('beforebegin', wrapperHTML)
    selectElement.parentNode.removeChild(selectElement)
  }

  createDefaultItemsContainer() {
    if (!this.hasItemsContainerTarget) {
      this.selectWrapperTarget.innerHTML += this.render(this.itemsContainerTemplateValue)
    }
  }

  createDefaultChoicesContainer() {
    if (!this.hasChoicesContainerTarget) {
      this.selectWrapperTarget.innerHTML += this.render(this.choicesContainerTemplateValue)
    }
  }

  addAvailableOptionsToItemsContainer () {
    let items

    if (this.hasOptgroupTarget) {
      items = this.renderOptgroupsToItems(this.optgroupTargets)
    } else {
      items = this.renderOptionsToItems(this.optionTargets)
    }

    let html = this.render(this.itemListTemplateValue, { items: items })

    this.morph(this.itemsContainerTarget, html)
  }

  updateItemStates () {
    this.addAvailableOptionsToItemsContainer()
    this.itemTargets.forEach((item) => {
      let option = this.optionWithValue(item.dataset.multiSelectValueParam)

      if (option.disabled) {
        item.classList.add(this.disabledClass)
      } else {
        item.classList.remove(this.disabledClass)
      }

      if (option.selected) {
        item.classList.add(this.selectedClass)
      } else {
        item.classList.remove(this.selectedClass)
      }
    })
  }

  updateChoiceStates () {
    let html = this.optionTargets.map(
      (option) => {
        if (option.selected) {
          return this.renderOptionToChoice(option)
        } else {
          return ''
        }
      }
    ).join('')

    this.morph(this.choicesContainerTarget, html)

    this.toggleWrapperSelectionClass()
  }

  toggleWrapperSelectionClass() {
    if (this.hasSelectedOptionsClass) {
      if (this.optionTargets.some(option => option.selected)) {
        this.selectWrapperTarget.classList.add(this.selectedOptionsClass)
      } else {
        this.selectWrapperTarget.classList.remove(this.selectedOptionsClass)
      }
    }
  }

  // EVENTS:

  submitForm (event) {
    let parentForm = this.selectTarget.closest('form')
    if (parentForm) {
      parentForm.requestSubmit()
      this.optionTargets.forEach((option) => {
        option.defaultSelected = option.selected
      })
    }
  }

  refresh (event) {
    this.updateItemStates()
    this.updateChoiceStates()
  }

  toggleOption (event) {
    let option = this.optionWithValue(event.params.value)

    if (option.selected) {
      this.deselectOption(event)
    } else {
      this.selectOption(event)
    }
  }

  selectOption (event) {
    let option = this.optionWithValue(event.params.value)
    if (option.disabled) { return }

    let item = this.itemWithValue(event.params.value)

    option.selected = true
    item.classList.add(this.selectedClass)

    event.preventDefault()

    _.defer(() => {
      this.updateChoiceStates()
      this.selectTarget.dispatchEvent(new Event('change'))

      if (this.closeOnSelectValue) {
        this.closeItemList(event)
      }
    })
  }

  deselectOption (event) {
    let option = this.optionWithValue(event.params.value)
    let item   = this.itemWithValue(event.params.value)

    option.selected = false
    item.classList.remove(this.selectedClass)

    event.preventDefault()

    _.defer(() => {
      this.updateChoiceStates()
      this.selectTarget.dispatchEvent(new Event('change'))
    })
  }

  updateItemListHeight (event) {
    if (this.hasItemsContainerTarget) {
      let windowHeight     = window.innerHeight
      let docScrollHeight  = document.documentElement.scrollHeight
      let contRect         = this.choicesContainerTarget.getBoundingClientRect()
      let contDiff         = contRect.bottom - contRect.top
      let contOffset       = contRect.top + window.pageYOffset
      let contHeaderHeight = this.hasItemsHeaderTarget ? this.itemsHeaderTarget.clientHeight : 0
      let contListHeight   = this.itemsListTarget.scrollHeight
      let contMarginTop    = parseFloat(getComputedStyle(this.itemsContainerTarget).marginTop)

      let height          = Math.min(windowHeight - contDiff, windowHeight - contDiff - contRect.top) - (contMarginTop / 2)
      let maxHeight       = contMarginTop + contHeaderHeight + contListHeight
      let availableHeight = docScrollHeight - contOffset - contMarginTop

      if (maxHeight > availableHeight) {
        maxHeight = availableHeight
      }

      this.itemsContainerTarget.style.height = `${height}px`
      this.itemsContainerTarget.style.maxHeight = `${maxHeight}px`
    }
  }

  resetItemListHeight() {
    if (this.hasItemsContainerTarget) {
      this.itemsContainerTarget.style.height = null
    }
  }

  closeItemListFromBackdrop (event) {
    if (this.itemsContainerTarget.contains(event.target)   ||
        this.choicesContainerTarget.contains(event.target) ||
        this.selectWrapperTarget.contains(event.target)    ||
        !document.contains(event.target)) {
      return
    }

    this.closeItemList(event)
  }

  toggleItemList (event) {
    if (this.itemsContainerTarget.classList.contains(this.visibleClass)) {
      return this.closeItemList(event)
    } else {
      return this.openItemList(event)
    }
  }

  openItemList (event) {
    this.itemsContainerTarget.classList.remove(this.hiddenClass)
    this.itemsContainerTarget.classList.add(this.visibleClass)

    _.defer(this.updateItemListHeight)
  }

  closeItemList (event) {
    this.itemsContainerTarget.classList.add(this.hiddenClass)
    this.itemsContainerTarget.classList.remove(this.visibleClass)

    _.defer(this.resetItemListHeight)
  }

  // UTILITY:

  optionWithValue (value) {
    return this.optionTargets.find((option) => option.value == value)
  }

  itemWithValue (value) {
    return this.itemTargets.find((item) => item.dataset.multiSelectValueParam == value)
  }

  renderOptgroupsToItems (optgroups) {
    return optgroups.map(
      (optgroup) => {
        let options = this.optionTargets.filter(option => option.parentNode == optgroup)
        let items   = this.renderOptionsToItems(options)

        return this.render(this.itemGroupTemplateValue, {
          optgroup: optgroup,
          items:    items
        })
      }
    ).join("\n")
  }

  renderOptionsToItems (options) {
    return options.map((option) => this.renderOptionToItem(option)).join("\n")
  }

  renderOptionToItem (option) {
    return this.render(this.itemTemplateValue, { option: option })
  }

  renderOptionToChoice (option) {
    return this.render(
      this.choiceTemplateValue,
      { option: option }
    )
  }
}
