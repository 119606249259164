import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = [
    'table',
    'tableWrapper',
    'navigateLeft',
    'navigateRight',
    'stickyCell'
  ]

  static classes = [
    'isHidden',
    'stickyCell',
    'stickyHead',
    'dragAndDropMode'
  ]

  static values = {
    allowDragAndDrop: { type: Boolean, default: true  },
    dropping:         { type: Boolean, default: false }
  }

  static outlets = [
    'timeline--row'
  ]

  get tableHead () {
    return this.hasTableTarget && this.tableTarget.querySelector('thead')
  }

  // DON'T USE outlets FOR cells FOR PERFORMANCE REASONS:
  get cells () {
    return this.findChildControllers('timeline--cell')
  }

  // DON'T USE outlets FOR reservations FOR PERFORMANCE REASONS:
  get reservations () {
    return this.findChildControllers('timeline--reservation')
  }

  get rightNow() {
    return new Date(new Date().getTime())
  }

  get widthOfQuarter () {
    return this._widthOfQuarter || (
      this._widthOfQuarter = Math.max(
        ...this.cells.map((cell) => cell.element.offsetWidth)
      )
    )
  }

  get widthOfHour () {
    return this.widthOfQuarter * 4
  }

  //
  // SETUP:
  //

  connect() {
    this.scrollToNow()
    this.displayScrollHelpers()
  }

  disconnect() {
    clearInterval(this.refreshScrubberInterval)
  }

  timelineRowOutletConnected (row, element) {
    this.setStickyCellPositions()
  }

  timelineRowOutletDisconnected (row, element) {
    this.setStickyCellPositions()
  }

  //
  // DRAG AND DROP:
  //

  set draggingReservationData (data) {
    this._draggingReservationData = data

    if (localStorage && localStorage.setItem) {
      localStorage.setItem('draggingReservationData', JSON.stringify(data))
    }
  }

  get draggingReservationData () {
    if (this._draggingReservationData) {
      return this._draggingReservationData
    } else if (localStorage && localStorage.getItem) {
      return this._draggingReservationData = JSON.parse(localStorage.getItem('draggingReservationData'))
    }
  }

  dragEnter (event) {
    event.preventDefault()

    clearTimeout(this.dragTimeout)

    this.element.classList.add(this.dragAndDropModeClass)
  }

  dragOver (event) {
    event.preventDefault()

    clearTimeout(this.dragTimeout)

    let row = this.timelineRowOutlets.find(
      (row) => row.element.contains(event.target)
    )

    if (row) {
      if (row != this.currentDragOverRow) {
        if (this.currentDragOverRow) {
          this.currentDragOverRow.dragLeave(event)
        }

        row.dragEnter(event)
        this.currentDragOverRow = row
      }
    }
  }

  dragLeave (event) {
    event.preventDefault()
    clearTimeout(this.dragTimeout)

    this.dragTimeout = setTimeout(() => {
      this.element.classList.remove(this.dragAndDropModeClass)

      if (this.currentDragOverRow) {
        this.currentDragOverRow.dragLeave(event)
        this.currentDragOverRow = null
      }
    }, 500)
  }

  dragDrop (event) {
    event.preventDefault()

    clearTimeout(this.dragTimeout)

    let row  = this.timelineRowOutlets.find(
      (row) => row.element.contains(event.target)
    )

    if (row) {
      row.dragDrop(event)
    }

    this.element.classList.remove(this.dragAndDropModeClass)
  }

  //
  // SCROLL:
  //

  getClock(hour, minute) {
    let h = `0${(Math.floor(hour))}`.slice(-2)
    let m = `0${(Math.floor(minute))}`.slice(-2)

    return `${h}:${m}`
  }

  leftPositionForTime(time, precise = true) {
    let thead      = this.tableHead
    let theadStyle = window.getComputedStyle(thead) || thead.currentStyle

    let quarter = parseInt(time.getMinutes() / 15)
    let clock   = this.getClock(time.getHours(), (quarter * 15))
    let offset  = (time.getMinutes() - (quarter * 15)) / 15

    let cell = this.cells.find((cell) => cell.timeValue == clock)
    if (cell) {
      let element  = cell.element.closest('td, th')
      let position = element.getBoundingClientRect().left + parseFloat(theadStyle.paddingLeft)

      if (precise) {
        position += (offset * element.offsetWidth)
      }

      return position
    } else {
      return null
    }
  }

  scrollToNow() {
    let x = this.leftPositionForTime(this.rightNow, false) || 0
    var windowWidth = window.innerWidth
    x = x - (windowWidth / 3)

    if(x > 0) {
      setTimeout(() => {
      this.tableWrapperTarget.scroll({left: x, behavior: 'smooth'})
    }, 100)
    }
  }

  scrollLeft (event) {
    this.scrollTo('left')
  }

  scrollRight (event) {
    this.scrollTo('right')
  }

  scrollTo (direction) {
    let width    = this.widthOfQuarter
    let position = this.tableWrapperTarget.scrollLeft

    if (window.innerWidth >= 600) {
      width = this.widthOfHour * 2
    }

    if (direction === 'left') {
      position -= width
    } else {
      position += width
    }

    this.tableWrapperTarget.scroll({
      left:      position,
      behavior: 'smooth'
    })
  }

  displayScrollHelpers(event) {
    let maxScrollLeft = this.tableWrapperTarget.scrollWidth - this.tableWrapperTarget.clientWidth

    if (this.tableWrapperTarget.scrollLeft > 0) {
      this.navigateLeftTarget.classList.remove(this.isHiddenClass)
    } else {
      this.navigateLeftTarget.classList.add(this.isHiddenClass)
    }

    if (this.tableWrapperTarget.scrollLeft < maxScrollLeft) {
      this.navigateRightTarget.classList.remove(this.isHiddenClass)
    } else {
      this.navigateRightTarget.classList.add(this.isHiddenClass)
    }
  }

  //
  // STICKY HEADER:
  //

  setStickyCellPositions() {
    let scrollLeftPostion = this.tableWrapperTarget.scrollLeft
    let stickyCells       = this.stickyCellTargets

    if (scrollLeftPostion > 0) {
      this.element.classList.add(this.stickyCellClass)
      stickyCells.forEach((cell) => cell.style.left = `${scrollLeftPostion}px`)
    } else {
      stickyCells.forEach((cell) => cell.style.left = "0px")
      this.element.classList.remove(this.stickyCellClass)
    }
  }

  setStickyHeadPosition() {
    let topPosition         = this.tableTarget.getBoundingClientRect().top
    let positiveTopPosition = Math.abs(topPosition)
    let tableHeadHeight     = 0

    if (topPosition < 0) {
      this.element.classList.add(this.stickyHeadClass)

      this.tableHead.style.top           = `${positiveTopPosition + tableHeadHeight}px`
      this.navigateRightTarget.style.top = `${positiveTopPosition}px`
      this.navigateLeftTarget.style.top  = `${positiveTopPosition}px`
    } else {
      this.element.classList.remove(this.stickyHeadClass)

      this.tableHead.style.top           = `${tableHeadHeight}px`
      this.navigateRightTarget.style.top = "0px"
      this.navigateLeftTarget.style.top  = "0px"
    }
  }
}
