import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = ["fromSelect", "tillSelect", "checkbox"]

  handleCheck(event) {
    // Clear the select values if the checkbox got checked
    if (event.target.checked) {
      this.fromSelectTarget.value = this.tillSelectTarget.value = '';
    }
  }

  selectChanged() {
    // Uncheck the checkbox if any of the selects has a value
    this.checkboxTarget.checked = (this.fromSelectTarget.value || this.tillSelectTarget.value) ? false : true
  }
}
