import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static targets = [
    'language',
    'pane',
    'panes',
    'tab'
  ]

  changeLanguage(event) {
    event.preventDefault()
    let tab = event.currentTarget
    let anchor = tab.querySelector('a').getAttribute('href')
    let pane = this.panesTarget.querySelector(anchor)

    this.paneTargets.forEach(pane => {
      pane.classList.remove('selected')
    })

    this.tabTargets.forEach(tab => {
      tab.classList.remove('selected')
    })

    tab.classList.add('selected')
    pane.classList.add('selected')
  }
}
