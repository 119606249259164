import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static classes = [ 'emptyPreview', 'previewImage', 'hidden' ]
  static targets = [ 'input', 'preview', 'previewWrapper' ]

  get files () { return this.inputTarget.files }
  get isPreviewed () {
    return this.hasPreviewTarget && this.previewTarget.querySelectorAll('img').length

  }

  connect () {
    if (this.isPreviewed) {
      this.showPreview()
    } else {
      this.hidePreview()
    }
  }

  showPreview() {
    if (this.hasPreviewWrapperTarget) {
      this.previewWrapperTarget.classList.remove(this.hiddenClass)
      this.previewWrapperTarget.classList.remove(this.emptyPreviewClass)
    }

    if (this.hasPreviewTarget) {
      this.previewTarget.classList.remove(this.hiddenClass)
      this.previewTarget.classList.remove(this.emptyPreviewClass)
    }
  }

  hidePreview() {
    if (this.hasPreviewWrapperTarget) {
      this.previewWrapperTarget.classList.add(this.hiddenClass)
      this.previewWrapperTarget.classList.add(this.emptyPreviewClass)
    }

    if (this.hasPreviewTarget) {
      this.previewTarget.classList.add(this.hiddenClass)
      this.previewTarget.classList.add(this.emptyPreviewClass)
    }
  }

  clear (event) {
    this.inputTarget.value = null
    this.clearPreview()
    this.hidePreview()
  }

  clearPreview() {
    this.previewTarget.innerHTML = ''
    this.previewTarget.classList.add(this.emptyPreviewClass)
    this.previewWrapperTarget.classList.add(this.emptyPreviewClass)
  }

  fillPreview() {
    if (this.files.length == 0) {
      return
    }

    for (let file of this.files) {
      let reader = new FileReader()

      reader.onload = function(event) {
        this.previewTarget.innerHTML += `<img src="${event.currentTarget.result}" class="${this.imagePreviewClass}" />`
      }.bind(this)

      reader.readAsDataURL(file)
    }

    this.previewTarget.classList.remove(this.emptyPreviewClass)
    this.previewWrapperTarget.classList.remove(this.emptyPreviewClass)
  }

  preview () {
    if (this.hasPreviewTarget) {
      this.clearPreview()
      this.fillPreview()
    }
  }
}
