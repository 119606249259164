import ApplicationController from '../application_controller'
import _ from 'underscore'
import Rails from '@rails/ujs'

export default class extends ApplicationController {

  static targets = [
    'popup',
    'select',
    'list',
    'listItem',
    'toggleButton',
    'closeButton',
    'openButton',
    'fromSelect',
    'tillSelect',
    'openingTimes',
    'dayLabel'
  ]

  static classes = [
    'visible',
    'hidden',
    'list',
    'listVisible',
    'item',
    'itemDisabled',
    'itemEnabled',
    'itemSelected',
    'timeslotsModified',
    'hiddenDayLabel'
  ]

  static values = {
    defaultTimeslots:      { type: Array,  default: []       },
    alternativeTimeslots:  { type: Array,  default: []       },
    rangeFrom:             { type: String, default: '13:00'  },
    rangeTill:             { type: String, default: '23:30'  },
    meal:                  { type: String, default: 'dinner' },
    availableTimeslotsUrl: { type: String, default: ''       }
  }

  get closed () {
    return this.timeslots.length == 0
  }

  get modified () {
    return !_.isEqual(this.timeslots, this.defaultTimeslotsValue)
  }

  get timeslots () {
    return _.map(
      this.selectOptions.filter((option) => option.selected),
      (option) => option.value
    )
  }

  set timeslots (newTimeslots) {
    this.selectOptions.forEach((option) => {
      option.selected = newTimeslots.indexOf(option.value) >= 0
    })
    this.refresh()
  }

  get selectOptions () {
    if (this.hasSelectTarget) {
      return Array.from(this.selectTarget.options)
    } else {
      return []
    }
  }

  connect () {
    if (this.defaultTimeslotsValue.length == 0) {
      if (this.hasFromSelectTarget && this.hasTillSelectTarget) {
        this.refreshDefaultTimeslots()
      }
    }

    if(this.hasSelectTarget) {
      this.createSelectList()
    }

    this.refresh()
  }

  createSelectList () {
    if (!this.hasSelectTarget) {
      return
    }

    if (!this.hasListTarget) {
      let list = document.createElement('ol')
      list.setAttribute(`data-${this.identifier}-target`, 'list')

      this.selectTarget.insertAdjacentElement('afterend', list)
    }

    this.listTarget.classList.add(this.listClass)
  }

  togglePopup(event) {
    if (!this.hasPopupTarget) {
      return
    }


    if(this.popupTarget.classList.contains(this.visibleClass)) {
      this.closePopup(event)
    } else {
      this.openPopup(event)
    }
  }

  openPopup (event) {
    if (!this.hasPopupTarget) {
      return
    }

    if (event) {
      event.preventDefault()
    }

    this.element.classList.add(this.listVisibleClass)
    this.popupTarget.classList.add(this.visibleClass)
    this.popupTarget.classList.remove(this.hiddenClass)
  }

  closePopup (event) {
    if (!this.hasPopupTarget) {
      return
    }

    if (event) {
      event.preventDefault()
    }

    this.element.classList.remove(this.listVisibleClass)
    this.popupTarget.classList.add(this.hiddenClass)
    this.popupTarget.classList.remove(this.visibleClass)
  }

  refreshAvailableTimeslots(event) {
    this.hideOrShowToggleButton()

    let url = this.availableTimeslotsUrlValue
    if (url.length) {
      url += `?from=${this.fromSelectTarget.value || this.rangeFromValue}&till=${this.tillSelectTarget.value || this.rangeTillValue}&meal=${this.mealValue}`

      Rails.ajax({
        url:      url,
        type:    'GET',
        success: (response) => {
          let timeslots = response.times
          let selected = this.timeslots

          this.morph(
            this.selectTarget,
            timeslots.map((timeslot) => {
              let selection = ''
              if (selected.indexOf(timeslot) >= -1) { selection = ' selected' }

              return `<option value="${timeslot}"${selection}>${timeslot}</option>`
            }).join()
          )

          this.refreshDefaultTimeslots()
          this.refresh()
        },
        error:  (response) => {
          console.log('refreshAvailableTimeslots error', response)
        }
      })
    }
  }

  refreshDefaultTimeslots () {
    let options = Array.from(this.fromSelectTarget.options)
    let values  = _.map(
      options.filter((option) => option.value.length >= 1),
      (option) => option.value
    ).sort()

    let fromValue = this.fromSelectTarget.value
    let tillValue = this.tillSelectTarget.value

    if (!fromValue && !tillValue) {
      this.defaultTimeslotsValue = []
      return
    }

    fromValue = fromValue || this.rangeFromValue
    tillValue = tillValue || this.rangeTillValue

    let index = values.indexOf(fromValue)
    values = values.slice(index)

    index = values.indexOf(tillValue)
    index = index <= -1 ? values.length : index + 1
    values = values.slice(0, index)

    this.defaultTimeslotsValue = values
  }

  restoreDefaults(event) {
    event.preventDefault()

    this.timeslots = this.defaultTimeslotsValue

    if (this.hasSelectTarget) {
      let event = new Event('change')
      this.selectTarget.dispatchEvent(event)
    }

    this.refresh()
  }

  refresh() {
    this.hideOrShowToggleButton()

    if (!this.hasListTarget || !this.hasSelectTarget) {
      return
    }

    this.listTarget.innerHTML = ''
    this.createListItems()

    this.updateStatus()
  }

  persist (event) {
    if (event.params && event.params.url) {
      let data = _.map(this.timeslots, (slot) => `timeslots[times][]=${encodeURI(slot)}`).join('&')
      if (!data.length) { data = 'timeslots[times][]=' }

      Rails.ajax({
        url:     event.params.url,
        type:    (event.params && event.params.method) || 'PUT',
        data:    data,
        success: (response) => {
          this.timeslots = response.times
        },
        error:   (response) => {
          console.log('persist error', response)
        }
      })
    }
  }

  updateStatus() {
    if (this.closed) {
      if (this.hasCloseButtonTarget) {
        this.closeButtonTarget.classList.add(this.hiddenClass)
        this.closeButtonTarget.classList.remove(this.visibleClass)
      }

      if (this.hasOpenButtonTarget) {
        this.openButtonTarget.classList.add(this.visibleClass)
        this.openButtonTarget.classList.remove(this.hiddenClass)
      }
    } else {
      if (this.hasCloseButtonTarget) {
        this.closeButtonTarget.classList.remove(this.hiddenClass)
        this.closeButtonTarget.classList.add(this.visibleClass)
      }

      if (this.hasOpenButtonTarget) {
        this.openButtonTarget.classList.remove(this.visibleClass)
        this.openButtonTarget.classList.add(this.hiddenClass)
      }
    }

    if (this.modified) {
      this.element.classList.add(this.timeslotsModifiedClass)
    } else {
      this.element.classList.remove(this.timeslotsModifiedClass)
    }
  }

  fetchClosedTimeslots (event) {
    this.timeslots = []
    return this.fetchTimeslots(event)
  }

  fetchOpenTimeslots (event) {
    this.timeslots = this.defaultTimeslotsValue
    return this.fetchTimeslots(event)
  }

  fetchTimeslots (event) {
    event.stopImmediatePropagation()
    event.preventDefault()

    let target = event.target
    if (!target) { return }

    Rails.ajax({
      url:     target.href,
      type:    (target.dataset && target.dataset.method) || 'GET',
      success: (response) => {
        this.timeslots = response.times
      },
      error:   (response) => {
        console.log('fetchTimeslots error', response)
      }
    })
  }

  toggleItem (event) {
    let item = this.listItemTargets.find((item) => {
      return item.getAttribute(`data-${this.identifier}-value-param`) == event.params.value
    })

    let option = this.selectOptions.find((option) => {
      return option.value == event.params.value
    })

    if (item.classList.contains(this.itemSelectedClass)) {
      item.classList.remove(this.itemSelectedClass)
      option.selected = false
    } else {
      item.classList.add(this.itemSelectedClass)
      option.selected = true
    }

    if (this.hasSelectTarget) {
      let event = new Event('change')
      this.selectTarget.dispatchEvent(event)
    }

    this.updateStatus()
  }

  createListItems() {
    this.selectOptions.forEach((option, index) => {
      if(option.value != null && option.value.length > 0) {
        this.listTarget.insertAdjacentElement(
          'beforeend',
          this.createListItem(option)
        )
      }
    })
  }

  createListItem(option) {
    let value = option.value

    let label = ''
    if (option.dataset.attributeAsLabel) {
      label = option.dataset.attributeAsLabel
    } else {
      label = option.text
    }

    let item = document.createElement('li')
    item.classList.add(this.itemClass)

    item.setAttribute(`data-${this.identifier}-value-param`, value)
    item.setAttribute(`data-${this.identifier}-target`, 'listItem')

    if (option.selected) {
      item.classList.add(this.itemSelectedClass)
    }

    if (option.disabled) {
      item.classList.add(this.itemDisabledClass)
    } else {
      item.classList.add(this.itemEnabledClass)
      item.setAttribute('data-action', `click->${this.identifier}#toggleItem`)
    }

    item.innerHTML = label

    return item
  }

  hideOrShowToggleButton() {
    if (this.hasToggleButtonTarget && this.hasFromSelectTarget && this.hasTillSelectTarget) {
      if (this.fromSelectTarget.value == '' && this.tillSelectTarget.value == '') {
        this.toggleButtonTarget.classList.remove(this.visibleClass)
        this.toggleButtonTarget.classList.add(this.hiddenClass)
      } else {
        this.toggleButtonTarget.classList.add(this.visibleClass)
        this.toggleButtonTarget.classList.remove(this.hiddenClass)
      }
    }
  }
}
