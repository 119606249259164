import _ from 'underscore'
import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static classes = ['isOpen', 'isClosed']

  static targets = ['modal', 'modalContent']

  static values = {
    unsavedChangesMessage: {
      type:    String,
      default: 'You have unsaved changes, are you sure you want to close the sidebar?'
    }
  }

  // Returns TRUE if the modal contains any forms with changed values:
  get hasUnsavedChanges () {
    return this.changesForFormsInModals.length > 0
  }

  get content () {
    return this.hasModalContentTarget && this.modalContentTarget.innerHTML
  }

  set content (newHTML) {
    if (this.hasModalContentTarget) {
      this.morph(this.modalContentTarget, newHTML)
    }
  }

  connect () {
    this.defaultContent = this.content

    if (this.hasModalTarget) {
      this.modalTarget.classList.remove(this.isOpenClass)
      this.modalTarget.classList.add(this.isClosedClass)
    }
  }

  disconnect () {
    this.close()
  }

  restoreDefaults () {
    this.content = this.defaultContent
  }

  showLocal (event) {
    let local = document.getElementById(event.params.id)
    if (local) {
      this.content = local.innerHTML
      this.show(event)

      event.preventDefault()
    }
  }

  show (event) {
    if (this.hasModalTarget && !this.modalTarget.open) {
      this.modalTarget.showModal()

      this.modalTarget.classList.add(this.isOpenClass)
      this.modalTarget.classList.remove(this.isClosedClass)
    }
  }

  close (event) {
    if (this.hasModalTarget && this.modalTarget.open) {
      this.modalTarget.close()
    }

    this.handleClose(event)
  }

  closeFromBackdrop (event) {
    // return unless clicked on backdrop:
    if (event.target != this.modalTarget) { return }

    // return when clicking on a scrollback that is technically part of the backdrop:
    let cursorX = event.pageX
    let modalX  = this.modalTarget.getBoundingClientRect().left
    if (cursorX > modalX) { return }

    if (this.hasUnsavedChanges) {
      if (confirm(this.unsavedChangesMessageValue)) {
        this.close(event)
      }
    } else {
      this.close(event)
    }
  }

  handleClose (event) {
    if (this.hasModalTarget) {
      this.modalTarget.classList.remove(this.isOpenClass)
      this.modalTarget.classList.add(this.isClosedClass)
    }

    this.restoreDefaults()
  }

  // START FORM PERSEVERANCE INITIATIVE //

  get formsInModal () {
    if (this.hasModalContentTarget) {
      return Array.from(this.modalContentTarget.querySelectorAll('form'))
    } else {
      return []
    }
  }

  get changesForFormsInModals () {
    return this.formsInModal.flatMap(
      (form) => this.changesInForm(form)
    )
  }

  changesInForm (form) {
    let changed = []
    let inputs  = form.querySelectorAll('select, textarea, input')

    for (var input, nodeName, i = 0; i < inputs.length; i++) {
      input = inputs[i]
      nodeName = input.nodeName.toLowerCase()

      if (nodeName == 'select') {
        changed = changed.concat(this.changesInFormSelect(input))
      } else {
        changed = changed.concat(this.changesInFormInput(input))
      }
    }

    return changed
  }

  changesInFormSelect (select) {
    let changed = []
    let options = select.querySelectorAll('option')

    for (var option, i = 0; i < options.length; i++) {
      option = options[i]

      if (option.selected != option.defaultSelected) {
        changed.push(option)
      }
    }

    return changed
  }

  changesInFormInput (input) {
    let changed   = []
    let inputType = input.type.toLowerCase()

    switch (inputType) {
      case 'checkbox':
      case 'radio':
        if (input.checked != input.defaultChecked) {
          changed.push(input)
        }

        break;

      default:
        if (input.value != input.defaultValue) {
          changed.push(input)
        }
    }

    return changed
  }

  // END FORM PERSEVERANCE INITIATIVE //
}