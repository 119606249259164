import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['row']

  static classes = ['sorting']

  connect() {
  }

  moveRowUp (event) {
    let target  = event.target
    let current = this.rowTargets.find((row) => row.contains(target))
    let index   = this.rowTargets.indexOf(current)

    if (index > 0) {
      this.element.classList.add(this.sortingClass)

      let other = this.rowTargets[index - 1]
      other.parentNode.insertBefore(current, other)
    }
  }

  moveRowDown (event) {
    let target  = event.target
    let current = this.rowTargets.find((row) => row.contains(target))
    let index   = this.rowTargets.indexOf(current)

    if (index < (this.rowTargets.length - 1)) {
      this.element.classList.add(this.sortingClass)

      let other = this.rowTargets[index + 1]
      other.parentNode.insertBefore(other, current)
    }
  }
}