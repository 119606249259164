import ApplicationController from 'controllers/application_controller'
import _ from 'underscore'

export default class extends ApplicationController {

  static values = { url: String }

  printWindow (event) {
    event.preventDefault()

    let printWindow = window.open(this.urlValue, '_blank')

    printWindow.onload = function() {
      setTimeout(function() { 
        printWindow.focus()
        printWindow.print()
      }, 300)
    }
  }
}
