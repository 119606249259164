import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static targets = [
    'wrapper',
    'dayInput',
    'openingTimes',
    'capacityInput',
    'dayLabel',
    'daySettings',
  ]

  static classes = [
    'useRestaurantTimes',
    'useRestaurantSeats',
    'useRestaurantSettings',
    'dayDisabled'
  ]

  static values = {
    enabled: { type: Boolean, default: true }
  }

  static outlets = [ 'backend--availability' ]

  get availability() {
    return this.backendAvailabilityOutlet
  }

  connect() {
    this.changeEnabledSetting()
  }

  renderSettings () {
    let element = this.hasWrapperTarget ? this.wrapperTarget : this.element

    element.classList.toggle(this.useRestaurantTimesClass,    this.availability.useRestaurantTimesValue)
    element.classList.toggle(this.useRestaurantSeatsClass,    this.availability.useRestaurantSeatsValue)
    element.classList.toggle(this.useRestaurantSettingsClass, this.availability.useRestaurantRealtimeSettingsValue)
    element.classList.toggle(this.dayDisabledClass,           !this.enabledValue)
  }

  changeEnabledSetting() {
    if (this.hasDayInputTarget) {
      this.enabledValue = this.dayInputTarget.checked
    }

    this.renderSettings()
  }
}
