import ApplicationController from './application_controller'
import * as Turbo from '@hotwired/turbo'
import _ from 'underscore'

export default class extends ApplicationController {
  connect () {
    this.clearTurboCache = this.clearTurboCache.bind(this)
    _.defer(this.clearTurboCache)
  }

  disconnect () {
    this.clearTurboCache()
  }

  clearTurboCache() {
    Turbo.cache.clear()
  }
}
