import ApplicationController from 'controllers/application_controller'
import toggleDisplay from 'lib/toggle_display'

export default class extends ApplicationController {

  static targets = [
    'addFields',
    'fields'
  ]

  addFormFields(event) {
    event.preventDefault()

    let anchor = event.target
    let parent = anchor.closest('.add-form-fields-container')

    let association = event.params.association
    let template    = event.params.template

    let target = parent.querySelector('.add-form-fields-target')
    let result = template.replace(new RegExp(`new_${association}`, 'g'), new Date().getTime())

    target.insertAdjacentHTML('beforeend', result)
  }

  removeFormFields(event) {
    toggleDisplay(
      event.currentTarget.closest('*[data-dynamic-form-target*="fields"]'),
      false
    )
  }
}
