import ApplicationController from 'controllers/application_controller'
import _ from 'underscore'

export default class extends ApplicationController {

  static targets = ['toggleButton', 'toggleTargets']

  toggleCheckboxes(event) {
    let selectAll   = this.toggleButtonTarget.dataset.selectAll
    let deselectAll = this.toggleButtonTarget.dataset.deselectAll
    
    event.preventDefault()
    
    if (this.element.dataset.allSelected === 'false') {
      this.toggleTargetsTarget.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
        checkbox.checked = true;
      })
      
      this.element.dataset.allSelected = 'true'
      this.toggleButtonTarget.innerHTML = deselectAll
    } else {
      this.toggleTargetsTarget.querySelectorAll('input[type="checkbox"]').forEach(checkbox => {
        checkbox.checked = false;
      })
      
      this.element.dataset.allSelected = 'false'
      this.toggleButtonTarget.innerHTML = selectAll
    }
  }
}
