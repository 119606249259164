import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static values = {
    refreshInterval: { type: Number, default: 10000 }
  }

  static targets = ['scrubber']

  static classes = ['outOfBounds']

  static outlets = ['timeline']

  get rightNow () {
    return new Date(new Date().getTime())
  }

  get scrubber () {
    if (this.hasScrubberTarget) {
      return this.scrubberTarget
    } else {
      return this.element
    }
  }

  connect() {
    this.updatePosition  = this.updatePosition.bind(this)
    this.refresher       = setInterval(this.updatePosition, this.refreshIntervalValue)

    this.updatePosition()
  }

  disconnect () {
    clearInterval(this.refresher)
  }

  updatePosition() {
    let position = this.timelineOutlet.leftPositionForTime(this.rightNow)
    let scrubber = this.scrubber

    if (position != null) {
      scrubber.classList.remove(this.outOfBoundsClass)
      scrubber.style.left = `${position}px`
    } else {
      scrubber.classList.add(this.outOfBoundsClass)
    }
  }
}