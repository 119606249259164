import ApplicationController from '../../application_controller'
import autoComplete from '@tarekraafat/autocomplete.js'

export default class extends ApplicationController {

  static targets = [
    'customerFields',
    'customerNameInput',
    'customerTelephoneInput',
    'customerEmailInput'
  ]

  get inputFields () {
    let array = []

    if (this.hasCustomerNameInputTarget)      { array.push(this.customerNameInputTarget)      }
    if (this.hasCustomerTelephoneInputTarget) { array.push(this.customerTelephoneInputTarget) }
    if (this.hasCustomerEmailInputTarget)     { array.push(this.customerEmailInputTarget)     }

    return array
  }

  connect() {
    this.inputFields.forEach((element) => this.initAutocomplete(element))
  }

  initAutocomplete (element) {
    let searchKey = element.dataset.autocompleteName

    let config = {
      selector:     `#${element.id}`,
      searchEngine: 'loose',
      threshold:    2,
      debounce:     100, // Milliseconds value
      resultsList: {
        maxResults: 3,
        noResults: true
      },
      resultItem: {
        element: (item, data) => {
          item.innerHTML = this.resultItemTemplate(item, data)
        }
      },
      data: {
        keys: [searchKey],
        src:  async(query) => {
          try {
            let source = await fetch(this.autoCompleteUrl(searchKey, query))
            let data   = await source.json()

            return data.results
          } catch (error) {
            return error
          }
        }
      },
      events: {
        input: {
          keyup: (event) => {
            if (event.keyCode == 13) {
              this.submitForm(event)
            }
          }
        }
      }
    }

    let autoCompleteJS = new autoComplete(config)
  }

  autoCompleteCustomerInformation(event) {
    let customer = event.currentTarget.dataset
    let changeEvent = new Event('change')

    if(customer.name && this.hasCustomerNameInputTarget) {
      this.customerNameInputTarget.value = customer.name
      this.customerNameInputTarget.dispatchEvent(changeEvent)
    }

    if(customer.email && this.hasCustomerEmailInputTarget) {
      this.customerEmailInputTarget.value = customer.email
      this.customerEmailInputTarget.dispatchEvent(changeEvent)
    }

    if(customer.telephone && this.hasCustomerTelephoneInputTarget) {
      this.customerTelephoneInputTarget.value = customer.telephone
      this.customerTelephoneInputTarget.dispatchEvent(changeEvent)
    }
  }

  autoCompleteUrl(searchKey, query, limit = 3) {
    return `${this.customerFieldsTarget.dataset.autocompleteUrl}?limit=${limit}&${searchKey}_like=${query}`
  }

  resultItemTemplate(item, data) {
    let template = `<div class='tt-suggestion' data-action='click->backend--reservations--autocomplete#autoCompleteCustomerInformation'
    data-name='${data.value.name}'
    data-email='${data.value.email}'
    data-telephone='${data.value.telephone}'> \
      <div class='name'>${data.value.name}</div> \
      <div class='email'>${data.value.email}</div> \
      <div class='telephone'>${data.value.telephone}</div> \
    </div>`

    return template
  }

  submitForm (event) {
    let inputField = event.target
    let parentForm = inputField.closest('form')

    if (parentForm) {
      parentForm.requestSubmit()
    }
  }
}
