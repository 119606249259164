import ApplicationController from './application_controller'
import _ from  'underscore'

// TODO: Convert to actual Stimulus controller, this is just a hacky
// way to convert the jQuery plugin quickly.
export default class extends ApplicationController {
  connect () {
    _.defer(() => {
      this.select = this.element.querySelector('select')

      this.container = document.createElement('div')
      this.container.classList.add('stars')

      this.options = this.select.querySelectorAll('option')
      this.values  = _.chain(this.options).map(_.property('value')).compact().value()

      this.setupRating()
      if (typeof Modernizr !== 'undefined' && Modernizr !== null ? Modernizr.touch : undefined) { this.setupRatingForTouch() }
    })
  }

  disconnect () {
    if (this.container) {
      this.container.parentNode.removeChild(this.container)
    }
  }

  setupRating() {
    this.values.forEach((value, index) => {
      let className = (index % 2) === 0 ? 'left-star' : 'right-star'
      let link      = document.createElement('span')

      link.classList.add('rate')
      link.classList.add(className)
      link.dataset.value = parseInt(value, 10)

      link.addEventListener('click',     this.clickRating.bind(this))
      link.addEventListener('mouseover', this.mouseoverRating.bind(this))
      link.addEventListener('mouseout',  this.mouseoutRating.bind(this))

      return this.container.appendChild(link)
    })

    this.valueDescription = document.createElement('div')
    this.valueDescription.classList.add('value')
    this.valueDescription.innerHTML = '-'

    this.container.appendChild(this.valueDescription)

    this.select.parentNode.appendChild(this.container)
    this.select.style.display = 'none'
  }

  setupRatingForTouch() {
    this.starWidth      = 0
    this.halfStarWidth  = 0
    this.previousRating = -1

    this.touchStarted   = false
    this.touchScrolling = false

    for (let link of Array.from(this.container.querySelectorAll('.rate'))) {
      link.onselectstart = () => false
      link.unselectable  = 'on'

      link.style['-moz-user-select']    = 'none'
      link.style['-webkit-user-select'] = 'none'
    }

    this.container.addEventListener('touchstart', event => {
      if (this.starWidth === 0) {
        this.starWidth += this.container.querySelector('.rate:nth-child(1)').offsetWidth
        this.starWidth += this.container.querySelector('.rate:nth-child(2)').offsetWidth

        this.halfStarWidth = this.container.querySelector('.rate').offsetWidth
      }

      let touch         = event.touches[0]
      this.touchStarted = { x: touch.screenX, y: touch.screenY }

      this.highlightTouchedStars(event)
    })

    this.container.addEventListener('touchmove', event => {
      if (this.touchStarted) {
        let touch = event.touches[0]

        let xDiff = Math.abs(this.touchStarted.x - touch.screenX)
        let yDiff = Math.abs(this.touchStarted.y - touch.screenY)

        if (xDiff > yDiff) {
          event.preventDefault()
        } else {
          this.touchScrolling = true
        }
        this.touchStarted = false
      }

      if (!this.touchScrolling) { this.highlightTouchedStars(event) }
    })

    this.container.addEventListener('touchend', () => {
      this.touchScrolling = false
    })
  }

  mouseoverRating(event) {
    let link   = event.currentTarget
    let { value }  = link.dataset
    let option = this.optionForValue(value)

    this.highlightRatingOption(option)
  }

  mouseoutRating(event) {
    this.highlightRatingOption(this.selectedOption())
  }

  cancelRating() {
    this.select.value = ''

    this.highlightRatingOption(this.selectedOption())
  }

  clickRating(event) {
    event.preventDefault()

    let link = event.currentTarget

    if (link.dataset.value === this.select.value) {
      this.cancelRating()
    } else {
      this.select.value = link.dataset.value
      this.highlightRatingOption(this.selectedOption())
    }
  }

  highlightTouchedStars(event) {
    let boundingRect    = this.container.getBoundingClientRect()
    let containerOffset = boundingRect.left + document.body.scrollLeft
    let offset          = event.pageX - containerOffset

    let stars  = parseInt(offset / this.starWidth)
    if ((offset - (stars * this.starWidth)) > this.halfStarWidth) { stars += 0.5 }
    let rating = Math.min(stars * 20, 100)

    if (rating !== this.previousRating) {
      if (rating >= 10) {
        this.select.value = rating
        this.highlightRatingOption(this.selectedOption(this.select))

        this.previousRating = rating
      } else {
        this.cancelRating()
      }
    }
  }

  highlightRatingOption(option) {
    let links         = this.container.querySelectorAll('.rate')
    let currentRating = parseInt(option.value, 10)

    this.morph(this.valueDescription, option.value ? option.innerHTML : '-')

    let result = []
    for (let link of Array.from(links)) {
      let rating = parseInt(link.dataset.value, 10)

      if (currentRating) {
        link.classList.toggle('highlighted', rating <= currentRating)
        result.push(link.classList.toggle('last',        rating === currentRating))
      } else {
        link.classList.remove('highlighted')
        result.push(link.classList.remove('last'))
      }
    }
  }

  selectedOption() {
    return this.select.options[this.select.selectedIndex]
  }

  optionForValue(value) {
    for (let option of Array.from(this.options)) {
      if (option.value === value) { return option }
    }
  }
}
