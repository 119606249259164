import ApplicationController from 'controllers/application_controller'

export default class extends ApplicationController {

  static values = {
    time: String
  }

  static targets = [
    'dragPreview'
  ]

  static outlets = [
    'timeline'
  ]

  static classes = [
    'dragPreview',
    'dragPreviewIndex'
  ]

  previewDraggableData (data, index) {
    if (!this.hasDragPreviewTarget) {
      this.element.innerHTML += `<div class="${this.dragPreviewClass}" data-timeline--cell-target="dragPreview">${data.preview}</div>`

      let indexClass = this.render(this.dragPreviewIndexClass, { index: index })
      this.dragPreviewTarget.classList.add(indexClass)
    }

    this.morph(this.dragPreviewTarget, data.preview)
  }

  removeDraggableDataPreview (data) {
    if (!this.hasDragPreviewTarget) { return }

    this.dragPreviewTarget.parentNode.removeChild(this.dragPreviewTarget)
  }
}
