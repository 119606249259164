import ApplicationController from 'controllers/application_controller'
import _ from 'underscore'

export default class extends ApplicationController {

  static classes = [
    'fauxPicker'
  ]

  connect () {
    this.addFauxPicker()
    this.updateColor()
  }

  addFauxPicker() {
    let labelFor   = this.element.querySelector('label').getAttribute('for')
    let fauxPicker = document.createElement('label')

    fauxPicker.classList.add(this.fauxPickerClass)
    fauxPicker.setAttribute('for', labelFor)
    this.element.appendChild(fauxPicker)
  }

  updateColor () {
    let value = this.element.querySelector('input').value
    this.element.querySelector('.' + this.fauxPickerClass).style.backgroundColor = value
  }
}
