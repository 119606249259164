import ApplicationController from 'controllers/application_controller'

const TIMELINE_RESEVATION_ROW_HEIGHT = 40

export default class extends ApplicationController {

  static values = {
    id:        Number,
    rowNumber: Number,
    tableId:   Number,
    quarter:   String,
    startsAt:  Number,
    endsAt:    Number,
    preview:   String,
    dragging:  Boolean,
    swapUrl:   String,
    index:     {
      type: Number, default: 0
    }
  }

  static classes = [
    'dragging',
    'overlap'
  ]

  static outlets = [
    'timeline'
  ]

  get id ()        { return this.idValue        }
  get rowNumber () { return this.rowNumberValue }
  get tableId ()   { return this.tableIdValue   }
  get quarter ()   { return this.quarterValue   }
  get startsAt ()  { return this.startsAtValue  }
  get endsAt ()    { return this.endsAtValue    }
  get preview ()   { return this.previewValue   }
  get dragging ()  { return this.draggingValue  }
  get swapUrl ()   { return this.swapUrlValue   }

  get stackHeight () {
    return Math.ceil(this.element.offsetHeight / TIMELINE_RESEVATION_ROW_HEIGHT)
  }

  set dragging (isDragging) {
    this.draggingValue = isDragging

    if (isDragging) {
      this.element.classList.add(this.draggingClass)
    } else {
      this.element.classList.remove(this.draggingClass)
    }
  }

  connect () {
    this.allowDragging = true
    this.element.draggable = false
  }

  enableDrag (event) {
    if (this.timelineOutlet.allowDragAndDropValue) {
      this.element.draggable = true
    }
  }

  disableDrag (event) {
    this.element.draggable = false
  }

  startDrag (event) {
    if (!this.hasTimelineOutlet) { return }

    this.dragging = true

    this.timelineOutlet.allowDragAndDropValue   = false
    this.timelineOutlet.draggingReservationData = {
      id:        this.id,
      rowNumber: this.rowNumber,
      tableId:   this.tableId,
      quarter:   this.quarter,
      startsAt:  this.startsAt,
      endsAt:    this.endsAt,
      preview:   this.preview,
      dragging:  true,
      swapUrl:   this.swapUrl
    }

    this.timelineOutlet.reservations.forEach(
      (reservation) => {
        if (reservation.id == this.id) {
          reservation.dragging = true
        }
      }
    )

    event.dataTransfer.clearData()
    event.dataTransfer.setData('text/html', this.previewValue)
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.dropEffect    = 'move'
  }

  stopDrag (event) {
    this.dragging = false
    this.element.draggable = false

    if (!this.hasTimelineOutlet) { return }

    this.timelineOutlet.allowDragAndDropValue   = !this.timelineOutlet.droppingValue
    this.timelineOutlet.draggingReservationData = null
    this.timelineOutlet.reservations.forEach(
      (reservation) => reservation.dragging = false
    )

    this.timelineOutlet.timelineRowOutlets.forEach(
      (row) => row.dragLeave(event)
    )
  }

  overlapsWith (other) {
    if (other.id == this.id) { return false }

    let startOverlaps = other.startsAt >= this.startsAt && other.startsAt  < this.endsAt
    let endOverlaps   = other.endsAt    > this.startsAt && other.endsAt   <= this.endsAt

    return startOverlaps || endOverlaps
  }

  addOverlapHighlight (other) {
    this.element.classList.add(this.overlapClass)
  }

  removeOverlapHighlight () {
    this.element.classList.remove(this.overlapClass)
  }
}
