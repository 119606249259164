export default function toggleDisplay(element, visible = null, displayInline = false) {
  let inline = (displayInline) ? 'inline-': ''

  if (visible != null) {
    if (visible == true){
      element.style.display = inline + 'block'
    } else {
      element.style.display = 'none'
    }
  } else {
    if (element.style.display === 'block') {
      element.style.display = 'none'
    } else {
      element.style.display = inline + 'block'
    }
  }
}
