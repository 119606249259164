import ApplicationController from '../../application_controller'

export default class extends ApplicationController {

  static targets = ['periodCancellationInput']

  checkAll(event) {
    let input = event.currentTarget
    let targets = this.periodCancellationInputTargets

    targets.forEach(target => {
      if(input.checked) {
        target.setAttribute('checked', true)
        target.checked = true
      }else{
        target.setAttribute('checked', false)
        target.checked = false
      }
    })
  }
}
