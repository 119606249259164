import ApplicationController from 'controllers/application_controller'
import Rails from '@rails/ujs'
import * as Turbo from '@hotwired/turbo'

export default class extends ApplicationController {

  static values = {
    number:  Number,
    tableId: Number
  }

  static outlets = [
    'timeline',
    'timeline--reservation'
  ]

  static classes = [
    'dragOver',
    'reservationStackHeight',
    'reservationIndex'
  ]

  // DON'T USE outlets FOR PERFORMANCE:
  get cells () {
    return this.findChildControllers('timeline--cell')
  }

  connect () {
    if (this.timelineReservationOutlets.length > 0) {
      this.stackReservationChains()
    }

    this.loaded = true
  }

  disconnect () {
    this.loaded = false
  }

  timelineReservationOutletConnected (reservation, element) {
    if (this.loaded) {
      this.stackReservationChains()
    }
  }

  timelineReservationOutletDisconnected (reservation, element) {
    if (this.loaded) {
      this.stackReservationChains()
    }
  }

  // HELPER METHODS:

  containsReservation (data) {
    return this.timelineReservationOutlets.some(
      (reservation) => reservation.id == data.id
    )
  }

  // STACKED RESERVATIONS:

  chainStackHeight (chain) {
    return chain.map(
      (reservation) => reservation.stackHeight
    ).reduce(
      (sum, height) => sum + height,
      0
    )
  }

  stackReservationChains() {
    let graph  = this.buildReservationGraph()
    let chains = this.findReservationChains(graph)
    let height = Math.max(...chains.map(chain => this.chainStackHeight(chain)), 1)

    this.setStackHeightClass(height)

    chains.forEach((chain) => {
      chain.forEach((reservation, index) => {
        let siblings = chain.slice(0, index)
        let height   = this.chainStackHeight(siblings)

        this.setReservationIndexClass(reservation, height)
      })
    })
  }

  buildReservationGraph() {
    let graph = new Map()

    this.timelineReservationOutlets.forEach((reservation) => {
      graph.set(reservation, [])
    })

    this.timelineReservationOutlets.forEach((reservation) => {
      this.timelineReservationOutlets.forEach((other) => {
        if (reservation.overlapsWith(other)) {
          graph.get(reservation).push(other)
          graph.get(other).push(reservation)
        }
      })
    })

    return graph
  }

  findReservationChains(graph) {
    const visited = new Set()
    const chains  = []

    this.timelineReservationOutlets.forEach((reservation) => {
      if (!visited.has(reservation)) {
        let chain = []
        this.depthFirstSearch(reservation, chain, visited, graph)

        chains.push(chain)
      }
    })

    return chains
  }

  depthFirstSearch(node, chain, visited, graph) {
    visited.add(node)
    chain.push(node)

    graph.get(node).forEach((neighbour) => {
      if (!visited.has(neighbour)) {
        this.depthFirstSearch(neighbour, chain, visited, graph);
      }
    })
  }

  setStackHeightClass (height) {
    [...Array(26).keys()].slice(1).map(
      (height) => this.render(this.reservationStackHeightClass, { height: height })
    ).forEach(
      (className) => this.element.classList.remove(className)
    )

    let className = this.render(this.reservationStackHeightClass, { height: height })
    this.element.classList.add(className)
  }

  setReservationIndexClass (reservation, index) {
    [...Array(26).keys()].slice(1).map(
      (index) => this.render(this.reservationIndexClass, { index: index })
    ).forEach(
      (className) => this.element.classList.remove(className)
    )

    let className = this.render(this.reservationIndexClass, { index: index })
    reservation.element.classList.add(className)
    reservation.indexValue = index
  }

  // DRAG AND DROP:

  dragEnter (event) {
    if (!this.hasTimelineOutlet) { return }

    event.preventDefault()

    this.element.classList.add(this.dragOverClass)

    let data = this.timelineOutlet.draggingReservationData

    if (data) {
      if (this.containsReservation(data)) { return }

      let previewIndex = 0

      this.timelineReservationOutlets.forEach((reservation) => {
        reservation.removeOverlapHighlight()

        if (reservation.overlapsWith(data)) {
          reservation.addOverlapHighlight()
          if (reservation.indexValue >= previewIndex) {
            previewIndex = reservation.indexValue + 1
          }
        }
      })

      let cell = this.cells.find((cell) => cell.timeValue == data.quarter)
      if (cell) {
        cell.previewDraggableData(data, previewIndex)
      }
    }
  }

  dragLeave (event) {
    event.preventDefault()

    this.element.classList.remove(this.dragOverClass)
    this.timelineReservationOutlets.forEach((reservation) => reservation.removeOverlapHighlight())

    if (!this.hasTimelineOutlet) { return }

    let data = this.timelineOutlet.draggingReservationData

    if (data) {
      this.cells.forEach(
        (cell) => { if (cell.timeValue == data.quarter) { cell.removeDraggableDataPreview(data) } }
      )
    }
  }

  dragDrop (event) {
    if (!this.hasTimelineOutlet) { return }

    this.timelineOutlet.droppingValue = true

    this.dragLeave(event)

    let data = this.timelineOutlet.draggingReservationData
    if (!data) {
      this.timelineOutlet.droppingValue = false
      return
    }

    let reservationPresent = this.timelineReservationOutlets.some(
      (reservation) => reservation.id == data.id
    )

    if (reservationPresent) {
      this.timelineOutlet.droppingValue = false
    } else {
      let originalReservation = this.timelineOutlet.reservations.find(
        (reservation) => reservation.id == data.id && reservation.tableId == data.tableId
      )

      if (originalReservation) {
        let originalReservationHTML = originalReservation.element.outerHTML
        originalReservation.element.parentNode.removeChild(originalReservation.element)

        let cell = this.cells.find((cell) => cell.timeValue == data.quarter)
        if (cell) {
          cell.element.innerHTML += originalReservationHTML
        }
      }

      let swapURL = this.render(data.swapUrl, { from: data.tableId, to: this.tableIdValue })
      if (swapURL && swapURL.length) {
        this.timelineOutlet.allowDragAndDropValue = false
        fetch(swapURL, {
          method: 'PUT',
          headers: {
            'Accept':           'text/vnd.turbo-stream.html',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token':     Rails.csrfToken()
          }
        }).then(
          (response) => response.text()
        ).then(
          (html) => {
            Turbo.renderStreamMessage(html)

            this.timelineOutlet.timelineRowOutlets.forEach(
              (row) => row.dragLeave(event)
            )
            this.timelineOutlet.allowDragAndDropValue = true
            this.timelineOutlet.droppingValue = false
          }
        )
      }
    }
  }
}