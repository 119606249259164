import ApplicationController from '../application_controller'
import _ from 'underscore'

export default class extends ApplicationController {
  static classes = ['hidden', 'preview']

  static values = {
    emptyTime:   { type: String, default: 'From'     },
    emptyAmount: { type: String, default: 'Maximaal' }
  }

  static targets = [
    'toggle',
    'preview',
    'time',
    'timeInput',
    'timeHint',
    'amount',
    'amountInput',
    'amountHint',
    'dialog',
    'form'
  ]

  timeInputTargetConnected (element) {
    this.updatePreview()
  }

  amountInputTargetConnected (element) {
    this.updatePreview()
  }

  connect () {
    this.requestSubmit = _.debounce(this.requestSubmit.bind(this), 20)

    if (this.hasDialogTarget) {
      this.dialogTarget.classList.add(this.hiddenClass)
    }

    this.updatePreview()
  }

  updatePreview (event) {
    if (this.hasTimeTarget) {
      this.timeTarget.innerHTML = this.timeInputTarget.value
    }

    if (this.hasAmountTarget) {
      this.amountTarget.innerHTML = this.amountInputTarget.value
    }

    if (this.timeInputTarget.value.length && this.amountInputTarget.value.length) {
      this.element.classList.add(this.previewClass)
      if (this.hasPreviewTarget) {
        this.previewTarget.classList.remove(this.hiddenClass)
      }
    } else {
      this.element.classList.remove(this.previewClass)
      if (this.hasPreviewTarget) {
        this.previewTarget.classList.add(this.hiddenClass)
      }
    }

    this.updateHint(event)
  }

  updateHint (event) {
    if (this.hasTimeHintTarget) {
      if (this.hasTimeInputTarget && this.timeInputTarget.value && this.timeInputTarget.value.length) {
        this.timeHintTarget.innerHTML = this.timeInputTarget.value
      } else {
        this.timeHintTarget.innerHTML = this.emptyTimeValue
      }
    }

    if (this.hasAmountHintTarget) {
      if (this.hasAmountInputTarget && this.amountInputTarget.value && this.amountInputTarget.value.length) {
        this.amountHintTarget.innerHTML = this.amountInputTarget.value
      } else {
        this.amountHintTarget.innerHTML = this.emptyAmountValue
      }
    }
  }

  submitForm (event) {
    if (this.requestSubmit.cancel) {
      this.requestSubmit.cancel()
    }

    this.requestSubmit()
  }

  requestSubmit () {
    if (this.hasFormTarget) {
      this.formTarget.requestSubmit()
    }
  }

  toggleDialog (event) {
    if (event) { event.preventDefault() }

    if (this.hasDialogTarget) {
      if (this.dialogTarget.classList.contains(this.hiddenClass)) {
        this.dialogTarget.classList.remove(this.hiddenClass)
      } else {
        this.dialogTarget.classList.add(this.hiddenClass)
      }
    }
  }

  closeDialog (event) {
    if (event) { event.preventDefault() }

    if (this.hasDialogTarget) {
      this.dialogTarget.classList.add(this.hiddenClass)
    }
  }
}