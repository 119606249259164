import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [
    'description',
    'customEmail',
    'customSMS',
    'options',
    'trigger',
    'message_refusal',
    'message_unreachable',
    'message_noplace'
  ]

  connect() {
    this.setCommunicationOption()
  }

  hideDescriptions() {
    this.descriptionTargets.forEach(target => {
      target.style.display = 'none'
    })
  }

  // used in period cancellations and confirmations
  setCommunicationOption() {
    this.hideDescriptions()

    this.optionsTargets.forEach(target => {
      if(target.checked) {
        document.getElementById(`reservation-communication-${target.value}`).style.display = 'block'
      }
    })
  }

  insertMessage(event) {
    event.preventDefault()

    let selector = event.currentTarget.getAttribute('href').replace('#', '')
    let target = document.getElementById(selector)
    let customEmailText = target.querySelector('.custom-email').innerText
    let customSMSText = target.querySelector('.custom-sms').innerText

    this.customEmailTarget.value = customEmailText
    this.customSMSTarget.value = customSMSText
  }
}
