import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  static values = {
    id: Number
  }

  static targets = [
    'enabledCheckbox'
  ]

  get enabled () {
    return this.enabledCheckboxTarget.checked
  }


  // SETUP:

  connect () {

  }

  // ACTIONS:

  enable () {
    if (!this.enabled) {
      this.enabledCheckboxTarget.checked = true

      let changeEvent = new Event('change')
      this.enabledCheckboxTarget.dispatchEvent(changeEvent)
    }
  }

  disable () {
    if (this.enabled) {
      this.enabledCheckboxTarget.checked = false

      let changeEvent = new Event('change')
      this.enabledCheckboxTarget.dispatchEvent(changeEvent)
    }
  }

}
