import applicationController from "../application_controller"

export default class extends applicationController {

  static targets = [
    'activeSettings',
    'partialActiveTrue',
    'partialActiveFalse',
    'formWrapper'
  ]

  connect(){
    this.showActiveExplanation()
  }

  showActiveExplanation(event) {
    this.partialActiveTrueTarget.style.display = 'none'
    this.partialActiveFalseTarget.style.display = 'none'

    this.activeSettingsTargets.forEach(setting => {
      if(setting.checked) {
        if(setting.value === 'true') {
          this.showOrHideBody(false)
          this.partialActiveTrueTarget.style.display = 'block'
        }else if(setting.value === 'false') {
          this.showOrHideBody(true)
          this.partialActiveFalseTarget.style.display = 'block'
        }
      }
    })
  }

  showOrHideBody(hide) {
    if(hide){
      this.formWrapperTarget.classList.add('body-hidden')
    }else {
      this.formWrapperTarget.classList.remove('body-hidden')
    }
  }
}
