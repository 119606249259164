import ApplicationController from './application_controller'
import * as WebAuthnJSON from '@github/webauthn-json'
import Rails from '@rails/ujs'

export default class extends ApplicationController {

  static targets = ['newCredentialNickname']

  static values = {
    createCredentialUrl:      { type: String, default: '/account/credentials/callback' },
    afterCreateCredentialUrl: { type: String, default: '/account/edit'                 },
    loginUrl:                 { type: String, default: '/webauthn/callback'            },
    afterLoginUrl:            { type: String, default: '/'                             }
  }

  static classes = [ "unsupported" ]

  get csrfToken () { return Rails.csrfToken()        }
  get supported () { return WebAuthnJSON.supported() }

  connect() {
    this.receiveCredentialForCreation = this.receiveCredentialForCreation.bind(this)
    this.receiveCredentialForLogin    = this.receiveCredentialForLogin.bind(this)

    this.finishCredentialCreation  = this.finishCredentialCreation.bind(this)
    this.finishLoginSession        = this.finishLoginSession.bind(this)

    this.handleError               = this.handleError.bind(this)

    this.checkCompatibility()
  }

  checkCompatibility() {
    if (this.supported) {
      this.element.classList.remove(this.unsupportedClass)
    }
    else {
      this.element.classList.add(this.unsupportedClass)
    }
  }

  initializeLoginSession (event) {
    var [credentialOptions, status, xhr] = event.detail

    WebAuthnJSON.get({
      publicKey: credentialOptions
    }).then(
      this.receiveCredentialForLogin
    ).catch(
      this.handleError
    )
  }

  initializeCredentialCreation (event) {
    var [credentialOptions, status, xhr] = event.detail

    if (credentialOptions["user"]) {
      WebAuthnJSON.create({
        publicKey: credentialOptions
      }).then(
        this.receiveCredentialForCreation
      ).catch(
        this.handleError
      )
    }
  }

  receiveCredentialForLogin (credential) {
    fetch(this.loginUrlValue, {
      method: "POST",
      body: JSON.stringify(credential),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": this.csrfToken
      },
      credentials: 'same-origin'
    }).then(this.finishLoginSession)
  }

  receiveCredentialForCreation (credential) {
    var url = this.createCredentialUrlValue.replace('{{nickname}}', this.newCredentialNicknameTarget.value)

    fetch(url, {
      method:  "POST",
      body:    JSON.stringify(credential),
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": this.csrfToken
      },
      credentials: 'same-origin'
    }).then(this.finishCredentialCreation)
  }

  finishLoginSession (response) {
    if (response.ok) {
      response.json().then((json) => {
        if (json.location) {
          window.location.replace(json.location)
        } else {
          window.location.replace(this.afterLoginUrlValue)
        }
      })
    } else if (response.error) {
      this.handleError(response)
    } else {
      alert("Sorry, something terribly wrong happened.")
    }
  }

  finishCredentialCreation (response) {
    if (response.ok) {
      window.location.replace(this.afterCreateCredentialUrlValue)
    } else if (response.error) {
      this.handleError(response)
    } else {
      alert("Sorry, something terribly wrong happened.")
    }
  }

  handleError (event) {
    console.log('handleError', event)
  }
}
